export const TITLE_SORT_OPTIONS = {
    'popularity': 'Popularity',
    'user_score': 'User Score',
    'release_date': 'Release Date',
    'pj_count': 'Page Count',
    'runtime': 'Runtime',
    'language': 'Language',
    'created_at': 'Date Added',
    'certification': 'Book Format',
};
