export const LIST_SORT_OPTIONS = {
    'pivot.order': 'List Order',
    name: 'Alphabetical',
    rating: 'Rating',
    pj_count: 'Page Count',
    popularity: 'Popularity',
    release_date: 'Release Date',
    runtime: 'Runtime',
    'pivot.created_at': 'Date Added',
};
