<media-item-header></media-item-header>

<div class="page-container container" *ngIf="person$ | async as person">
  <div class="left">
    <div class="img-container">
      <media-image [src]="person.poster" [alt]="person.name"></media-image>
      <a
        class="edit-button"
        mat-icon-button
        [routerLink]="['edit']"
        *ngIf="currentUser.hasPermission('people.edit')"
      >
        <mat-icon svgIcon="edit"></mat-icon>
      </a>
    </div>
    <div class="details">
      <div class="header" trans>Basic Info</div>
      <div class="values" *ngIf="person.known_for">
        <div class="name" trans>Known For</div>
        <div class="value" trans>{{ person.known_for }}</div>
      </div>
      <div class="values gender-values" *ngIf="person.gender">
        <div class="name" trans>Gender</div>
        <div class="value" trans>{{ person.gender }}</div>
      </div>
      <div class="values" *ngIf="person.known_for">
        <div class="name" trans>Total Books</div>
        <div class="value">{{ creditsCount$ | async }}</div>
      </div>
      <div class="values" *ngIf="person.death_date">
        <div class="name" trans>Date of Death</div>
        <div class="value">{{ person.death_date | formattedDate }}</div>
      </div>
      <div class="values" *ngIf="person.birth_date">
        <div class="name" trans>Date of Birth</div>
        <div class="value">{{ person.birth_date | formattedDate }}</div>
      </div>
      <div class="values" *ngIf="person.birth_place">
        <a class="name" href="{{ person.birth_place }}" target="_blank">Visit Website &#10140;</a>
      </div>
    </div>
  </div>
  <div class="right">
    <h1>{{ person.name }}</h1>
    <div class="biography" *ngIf="person.description">
      <div class="right-col-title" trans>About</div>
      <p moreLessText="600" [textContent]="person.description"></p>
    </div>

    <ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

    <div class="credits-container">
      <div class="right-col-title" trans>Books</div>
      <mat-expansion-panel
        *ngFor="let creditGroup of credits$ | async | keyvalue: originalOrder; first as isFirst"
        [expanded]="isFirst"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="credit-group-name" *ngIf="creditGroup.key === 'cast'" trans>Author</span>
            <span class="credit-group-name" *ngIf="creditGroup.key === 'writing'" trans>Editor</span>
            <span class="credit-group-name" *ngIf="creditGroup.key === 'directing'" trans>Publisher</span>
            &nbsp; ({{ creditGroup.value.length }}&nbsp;<span *ngIf="creditGroup.value.length === 1" trans>book</span>
            <span *ngIf="creditGroup.value.length !== 1" trans>books</span>)

          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="credits">
          <ng-container
            *ngFor="
              let credit of creditGroup.value;
              index as index;
              last as isLast;
              trackBy: trackByFn
            "
          >
            <div class="credit">
              <media-image [src]="credit.poster" [alt]="credit.name" size="small"></media-image>
              <div class="meta">
                <a [routerLink]="url.generate(credit)" class="title">{{ credit.name }}</a>
                <div>
                  <span class="description1" >{{ (credit.is_series ? 'Audiobook' : 'eBook') | trans }}
                    <span class="description1" *ngIf="credit.pj_count"> | {{ credit.pj_count }} {{ 'Pages' | trans }}</span>
                    <span class="description1" *ngIf="credit.runtime"> | {{ credit.runtime }} {{ 'min' | trans }}</span>
                  </span>                
                </div>
                <div class="description">{{ credit.description }}</div>
                
                <ng-container *ngIf="credit.episodes">
                  <div class="episode-list">
                    <div class="episode-credit" *ngFor="let episodeCredit of credit.episodes">
                      <div class="episode-name">
                        <span>- </span>
                        <a [routerLink]="url.generate(episodeCredit, credit)">{{
                          episodeCredit.name
                        }}</a>
                        <span *ngIf="episodeCredit.year"> ({{ episodeCredit.year }})</span>
                        <span class="episode-separator"> ... </span>
                        <span class="season-episode-number-container">
                          (<season-episode-number [episode]="episodeCredit"></season-episode-number
                          >)
                          <span class="episode-separator"> ... </span>
                        </span>
                        <span>
                          <span *ngIf="episodeCredit.pivot.department === 'cast'">{{
                            episodeCredit.pivot.character
                          }}</span>
                          <span *ngIf="episodeCredit.pivot.department !== 'cast'" trans>{{
                            episodeCredit.pivot.job
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="no-style load-episodes-button"
                    (click)="loadFullTitleCredits(person, credit, creditGroup.key)"
                    *ngIf="credit.credited_episode_count > 5 && credit.episodes.length === 5"
                    [transValues]="{count: credit.credited_episode_count}"
                    [disabled]="loadingAdditionalCredits$ | async"
                    trans
                  >
                    Show all :count episodes
                  </button>
                </ng-container>
              </div>
              <div class="year">{{ credit.year }}</div>
            </div>
            <div
              class="separator"
              *ngIf="!isLast && credit.year !== creditGroup.value[index + 1]?.year"
            ></div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </div>

    <ad-host slot="ads.person" class="person-ad-host"></ad-host>
  </div>
</div>

<footer></footer>
