export const MESSAGES = {
    TITLE_UPDATE_FAILED: 'There was an issue with updating this title.',
    TITLE_UPDATE_SUCCESS: 'Title updated.',
    TITLE_CREATE_SUCCESS: 'Title created.',
    SEASON_CREATE_SUCCESS: 'Season created.',
    SEASON_DELETE_SUCCESS: 'Season deleted.',
    CREDIT_UPDATE_SUCCESS: 'Credit updated.',
    CREDIT_ADD_SUCCESS: 'Credit added.',
    CREDIT_REMOVE_SUCCESS: 'Credit removed.',
    EPISODE_DELETE_SUCCESS: 'Episode deleted.',
    EPISODE_UPDATE_SUCCESS: 'Episode updated.',
    EPISODE_CREATE_SUCCESS: 'Episode created.',
    WATCHLIST_ADD_SUCCESS: 'Added to Readlist.',
    WATCHLIST_REMOVE_SUCCESS: 'Removed from Readlist.',
    LIST_ITEM_ADD_SUCCESS: 'Added item to list.',
    LIST_ITEM_REMOVE_SUCCESS: 'Removed item from list.',
    LIST_CREATE_SUCCESS: 'List created.',
    LIST_UPDATE_SUCCESS: 'List updated.',
    LIST_DELETE_SUCCESS: 'List deleted.',
    REVIEW_CREATE_SUCCESS: 'Review created.',
    REVIEW_DELETE_SUCCESS: 'Review deleted.',
    NEWS_CREATE_SUCCESS: 'News article created.',
    NEWS_UPDATE_SUCCESS: 'News article updated.',
    NEWS_DELETE_SUCCESS: 'News articles deleted.',
    IMAGE_UPDATE_SUCCESS: 'Image updated.',
    VIDEO_CREATE_SUCCESS: 'Video added.',
    VIDEO_CREATE_SUCCESS_NEEDS_APPROVAL: "Video added. We'll review and approve it as soon as possible.",
    VIDEO_CREATE_FAILED: 'Could not create video.',
    VIDEO_UPDATE_SUCCESS: 'Video updated.',
    VIDEO_UPDATE_FAILED: 'Could not update video.',
    VIDEO_DELETE_SUCCESS: 'Video deleted.',
    TAG_DETACH_SUCCESS: 'Tag detached.',
    PERSON_CREATE_SUCCESS: 'Person created.',
    PERSON_UPDATE_SUCCESS: 'Person updated.',
    COPY_TO_CLIPBOARD_SUCCESS: 'Copied link to clipboard.',
    IMAGE_DELETE_SUCCESS: 'Image deleted.',
    IMAGE_CREATE_SUCCESS: 'Image added.',
    LISTS_MANUALLY_UPDATE_SUCCESS: 'Lists updated.',
    NEWS_MANUALLY_UPDATE_SUCCESS: 'News updated.',
    SITEMAP_GENERATED: 'Sitemap generated.',
};

