<section class="left">
  <div class="img-container">
    <media-image [src]="titlePage.title.poster" size="medium"></media-image>
  </div>
  <div class="details">
    <div class="detail with-separator" *ngIf="titlePage.shortCredits.cast?.length as castCount">
      <div class="name">
        <span *ngIf="castCount === 1" trans>Author</span>
        <span *ngIf="castCount !== 1" trans>Authors</span>
      </div>
      <div class="value">
        <people-list-widget
          [people]="titlePage.shortCredits.cast"
          [limit]="3"
        ></people-list-widget>
      </div>
    </div>
    <div class="detail with-separator" *ngIf="titlePage.shortCredits.writers?.length as writerCount">
      <div class="name">
        <span *ngIf="writerCount === 1" trans>Editor</span>
        <span *ngIf="writerCount !== 1" trans>Editors</span>
      </div>
      <div class="value">
        <people-list-widget
          [people]="titlePage.shortCredits.writers"
          [limit]="3"
        ></people-list-widget>
      </div>
    </div>
    <div class="detail with-separator" *ngIf="titlePage.shortCredits.directors?.length as directorCount">
      <div class="name">
        <span *ngIf="directorCount === 1" trans>Publisher</span>
        <span *ngIf="directorCount !== 1" trans>Publishers</span>
      </div>
      <div class="value">
        <people-list-widget
          [people]="titlePage.shortCredits.directors"
          [limit]="3"
        ></people-list-widget>
      </div>
    </div>
    <div class="detail" *ngIf="titlePage.shortCredits.creators?.length">
      <div class="name" trans>Created By</div>
      <div class="value">
        <people-list-widget
          [people]="titlePage.shortCredits.creators"
          [limit]="3"
        ></people-list-widget>
      </div>
    </div>
    <div class="detail">
      <div class="name" trans>Description</div>
      <div class="value">
        <span moreLessText="340" [textContent]="description" *ngIf="description"></span>
        <span *ngIf="!description">{{ 'No overview has been added yet.' | trans }}</span>
      </div>
    </div>
  </div>
</section>

<section class="right">
  <title-action-buttons [item]="titlePage.activeEpisode || titlePage.title"></title-action-buttons>
  <div class="video-cover">
    <button
      mat-flat-button color="accent"
      (click)="titlePage.playVideo(titlePage.primaryVideo)"
      *ngIf="titlePage.primaryVideo"
    >
      <span trans>Read Now</span>
      <mat-icon svgIcon="exit-to-app"></mat-icon>
    </button>
  </div>
</section>